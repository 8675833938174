<template>
  <div class="page">
    <top />
    <div style="width: 100%; height: 55px"></div>
    <img
      style="width: 100%; height: 84px"
      src="../../assets/author2.png"
      alt=""
    />
    <div class="top4">
      <div>
        <img :src="imageUrl(head_pic)" alt="" />
      </div>
      <div style="margin-top: 5px">{{ nickname }}</div>
      <div class="number">
        上传作品<span style="margin-left: 10px"
          >{{ counts != "" ? counts : 0 }}张</span
        >
      </div>
      <div style="margin-top: 10px">粉丝数量 : {{ numberoffans }}</div>
      <div class="focus" v-if="follow == 'no'" @click="fllowDo(1)">关注+</div>
      <div class="focus" v-else @click="fllowDo(2)">已关注</div>
	  
	  <div class="tags-warp" v-if="tags_list.length>0">
	  	<div class="tags-list">
	  		<div class="tags-item" :class="tags_index==item.id?'active':''" v-for="(item, index) in tags_list" :key="index" @click="totags(item.title,item.id)">{{item.title}}</div>
	  	</div>
	  </div>
    </div>
    <div class="mid4">
		<el-select
			style="width: 47%; margin-bottom: 12px"
			multiple 
			v-model="format_checkeds"
			@change="changeformat"
			placeholder="选择格式"
		>
			<el-option
			  :label="itm"
			  :value="itm"
			  v-for="(itm, idx) in format_arr"
			  :key="idx"
			></el-option>
		</el-select>
      <el-select
        v-model="type"
        style="width: 47%; margin-bottom: 12px"
        placeholder="选择分区"
        @change="changes"
      >
        <el-option
          v-for="(v, k) in cate"
          :key="k"
          :label="v.typename"
          :value="v.id"
        >
          {{ v.typename }}</el-option
        >
      </el-select>
      <el-select
        v-model="choose_type[index]"
        style="width: 47%; margin-bottom: 12px"
        :placeholder="item.typename"
        v-for="(item, index) in arctype"
        :key="index"
        @change="change"
      >
        <el-option label="全部" value=""></el-option>
        <el-option
          :label="itemm.typename"
          :value="itemm.id"
          v-for="(itemm, indexx) in item.son"
          :key="indexx"
        >
        </el-option>
      </el-select>
	  <el-select
	  	style="width: 47%; margin-bottom: 12px"
	    v-model="show_model"
	    @change="changemodel"
	  >
	    <el-option
	      label="图片模式"
	      value="1"
	    ></el-option>
	    <el-option
	      label="样衣模式"
	      value="2"
	    ></el-option>
	  </el-select>
    </div>
    <div class="input1">
      <div @click="search"><img src="../../assets/search2.png" /></div>
      <input type="text" placeholder="请输入搜索内容" v-model="key_word" />
    </div>
    <div class="bottom4">
      <div class="bottomimg">
		  <div
		    class="bottomimg2"
		    v-for="(item, index) in list"
		    :key="index"
		    @click="goDetails('Details', item.aid)"
		  >
		    <img
		      v-if="item.sales_num >= hot_limit"
		      class="poa"
		      src="../../assets/bao.png"
		      alt=""
		    />
		    <img :src="imageUrl(item.litpic)" v-if="show_model=='1'"
		    />
			<div class="yiwrap" v-if="show_model=='2'">
				<img class="closeimg" :src="imageUrl(item.clothingTem)" alt="" v-if="item.clothingTem">
				<div v-if="item.repeat == 1" :id="'img'+item.aid" class="logo" :style="{
				  'z-index':'1',
				  position: 'absolute', 
				  width: '100%',
				  height: '100%',
				  backgroundImage: 'url(' + imageUrl(item.imgs) + ')',
				  backgroundSize: '39px',
				}"></div>
				<img v-if="item.repeat != 1" :id="'img'+item.aid" class="logo1" :src="imageUrl(item.imgs)" alt=""/>
				<div class="bj" :style="{ backgroundColor: item.color }"></div>
			</div>
		  </div>
        
      </div>
      <div class="page">
        <el-pagination
          :hide-on-single-page="count <= size ? true : false"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="size"
          :pager-count="5"
          layout="total, prev, pager, next ,jumper"
          :total="count"
        >
        </el-pagination>
      </div>
    </div>

    <info />
    <div style="width: 100%; height: 60px; background-color: #f6f6f6"></div>
    <foot />
  </div>
</template>

<script>
import top from "../../components/common/header.vue";
import foot from "../../components/common/footer.vue";
import info from "../../components/common/qrCode.vue";
import {
  authorDetail,
  fllowDo,
  checkOriginalAuth,
  topCate,
} from "../../request/api";
export default {
  components: {
    top,
    foot,
    info,
  },
  data() {
    return {
      list: "",
      follow: "", //是否关注
      arctype: "", //分类
      type: 1, //所属区域
      choose_type: [], //选中的分类
      key_word: "", //搜索内容
      count: 1,
      counts: 0,
      size: 10,
      page: 1,
      head_pic: "", //作者头像
      nickname: "", //作者名称
      users_id: "", //作者id
      userInfo: "",
      levelShow: false,
      thisType: 1,
      numberoffans: 0,
      cate: "",
	  tags_list:[],
	  tags_index:0,
	  tag_key:'',
	  show_model:'1',
	  format_checkeds:[],
	  format_arr:[]
    };
  },
  created() {
    if (this.$store.getters.getQuery1.length !== 0) {
      this.choose_type = this.$store.getters.getQuery1;
    }
    if (this.$store.getters.getPage1.length !== 0) {
      this.page = this.$store.getters.getPage1;
    }
    topCate().then((res) => {
      this.cate = res.data;
    });
    // if(this.$store.getters.getType.length !== 0){
    this.type = this.$store.getters.getType;
    console.log(this.type);
    // }
    this.getData();
  },
  methods: {
    open() {
      this.$alert("请购买原创vip浏览原创作品", "", {
        confirmButtonText: "确定",
        center: true,
      });
    },
    //获取数据
    getData() {
      authorDetail({
        author_id: this.$route.query.author_id,
        choose_type: this.type + "," + this.choose_type.toString(),
		formats:this.format_checkeds.toString(),
        key_word: this.key_word,
		tag_key:this.tag_key,
        type: this.type,
        page: this.page,
        size: this.size,
      }).then((res) => {
        // console.log(res);
        this.list = res.data.authorAbout;
		this.tags_list = res.data.tags_list;
        this.follow = res.data.follow;
        this.arctype = res.data.arctype;
        this.count = res.data.count == "" ? 0 : res.data.count;
        this.counts = res.data.counts == "" ? 0 : res.data.counts;
        this.numberoffans = res.data.numberoffans;
        this.head_pic = res.data.authorInfo.head_pic;
        this.nickname = res.data.authorInfo.nickname;
        this.users_id = res.data.authorInfo.users_id;
		
		this.hot_limit = res.data.hot_limit;
		this.format_arr = res.data.format_arr;
      });
    },
    go(url) {
      this.$router.push({
        name: url,
      });
    },
    goDetails(url, aid) {
      this.$store.commit("setQuery1", this.choose_type);
      this.$store.commit("setPage1", this.page);
      checkOriginalAuth({
        aid: aid,
      }).then((res) => {
        console.log('goDetails:',res);
        if (res.code == 500) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          return;
        }
        if (res.code == 200) {
          this.$router.push({
            name: url,
            query: {
              aid: aid,
            },
          });
        }
      });
    },
    //分页
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
	changeformat(){
		this.page = 1;
		this.getData();
	},
	totags(title,index){
		if(this.tags_index==index){
			this.tags_index = 0;
			this.tag_key = '';
		}else{
			this.tags_index = index;
			this.tag_key = title;
		}
	    this.page = 1;
	    this.getData();
	},
    search() {
      this.getData();
    },
	changemodel(val) {
	  this.show_model = val;
	},
    changes(val) {
      console.log(this.thisType);
      if (val == 20) {
        checkOriginalAuth({}).then((res) => {
          console.log(res);
          if (res.code == 500) {
            this.open();
            this.type = this.thisType;
            return;
          }
          if (res.code == 200) {
            this.thisType = val;
            this.$store.commit("setType", this.type);
            this.page = 1;
            this.getData();
            this.choose_type = [];
          }
        });
      } else {
        this.thisType = val;
        this.$store.commit("setType", this.type);
        this.page = 1;
        this.getData();
        this.choose_type = [];
      }
    },
    change(val) {
      this.page = 1;
      console.log(this.choose_type);
      //  console.log(this.type)
      this.getData();
    },
    fllowDo(type) {
      fllowDo({
        aid: this.users_id,
        type: type,
      }).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getData();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less">
.page {
  background: #f6f6f6;

  .top4 {
    text-align: center;
    position: relative;
    top: -45px;
    font-size: 13px;

    .number {
      font-size: 12px;
      margin-top: 8px;
    }

    img {
      border-radius: 50%;
      width: 80px;
      height: 80px;
    }

    .focus {
      color: #ffffff;
      background-color: #e66617;
      margin: 0 auto;
      margin-top: 9px;
      width: 73px;
      height: 24px;
      border-radius: 3px;
      line-height: 24px;
    }
  }

  .mid4 {
    position: relative;
    top: -30px;
    padding: 0 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .input1 {
    position: relative;
    top: -30px;
    border: 1px #08243c solid;
    border-radius: 18px;
    display: flex;
    align-items: center;
    padding: 9px 10px;
    margin: 0 10px;

    img {
      width: 17px;
      height: 17px;
    }

    input {
      background: none;
      outline: none;
      border: none;
      margin-left: 2%;
      width: 85%;
    }
  }

  .bottom4 {
    position: relative;
    top: -20px;
    padding: 10px;

    .bottomimg {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      img {
       width: 100%;
       height: 100%;
      }
    }
	.bottomimg2 {
      position: relative;
      width: 170px;
      height: 170px;
      margin-bottom: 10px;
    }
    .page {
      position: relative;
      left: -15%;
      transform: scale(0.7);
    }
  }
}
.el-message-box {
  width: 300px;
  height: 120px;
}
.poa {
  position: absolute;
  width: 26px !important;
  height: 30px !important;
  left: 6px;
  top: 8px;
  z-index: 3;
  img {
    position: absolute;
    width: 100% !important;
    height: 100% !important;
  }
}

.tags-warp{
	display: flex;
	align-items: center;
	margin-top: 12px;
	justify-content: center;
	.tags-list{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;
		.tags-item{
			font-size: 12px;
			color: #08243c;
			border: 1px solid #08243c;
			padding: 3px 15px;
			border-radius: 20px;
			margin: 0 5px 5px 5px;
			cursor: pointer;
			font-size: 14px;
		}
		.tags-item.active{
			background-color: #08243c;
			color: #fff;
		}
	}
}
.page .bottom4 .bottomimg img.closeimg{
	position: absolute;
	left:0;
	top:0;
	width: 100%;
	height: 100%;
	z-index: 2;
}
.page .bottom4 .bottomimg img.logo1{
	position: absolute; 
	width: 38px;
	height: 38px;
	top: 63px;
	left: 59px;
	z-index: 1;
}
.bj{
	position: absolute;
	left:0;
	top:0;
	width: 100%;
	height: 100%;
	z-index: 0;
}
</style>
